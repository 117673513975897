import React from 'react'
import PackageRegPage from './_package.js'

export default function MultipleEventPackagePage() {
  return (
    <PackageRegPage
      heading={'Multiple Event Package'}
      jotformIds={'211267105978157'}
      text={
        <p style={{ textAlign: 'center' }}>
          Access to 3 events
          <br />
          Includes a paperback book
          <br />
          $55 + shipping
        </p>
      }
    />
  )
}
